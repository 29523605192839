import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";

const Post = ({ title,time }) => {
  const [post, setPost] = useState({});
 

  useEffect(() => {
    const getPost = async () => {
      const resp = await fetch(
        `https://assignment.koteswar-meesala.workers.dev/?key=${title}`
      );
      const postResp = await resp.text();
      setPost(postResp);
    };

    getPost();
  }, [title]);

  if (!Object.keys(post).length) return <div />;

  return (
    <div>
      <h1>{title}</h1>
      <p>{post}</p>
      <p>
        <em>Published {new Date(parseInt(time)).toLocaleString()}</em>
      </p>
      <p>
        <Link to="/">Go back</Link>
      </p>
    </div>
  );
};

export default Post;