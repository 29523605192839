import { Router } from "@reach/router";
import Posts from './components/posts';
import Post from './components/post';
import './App.css';

function App() {
  return (
    <Router>
      <Posts path="/" />
      <Post path="/posts/:title/:time" />
    </Router>
  );
}

export default App;
